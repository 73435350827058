import { useStaticQuery, graphql } from "gatsby";
import React,{useEffect, useState} from "react";
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import { Link } from "@StarberryUtils";
import ShowMoreText from 'react-show-more-text';
import ModalTiny from "react-bootstrap/Modal"
import CommentForm from "./forms/comments-reviews"
import { useLocation } from "@reach/router"

const LocalLifeComments=(props)=> {

  const data = useStaticQuery(graphql`{
    allStrapiLocalLifeComments(
      filter: {status: {eq: "Approved"}}
      limit: 99999
      sort: {fields: createdAt, order: DESC}
    ) {
      edges {
        node {
          name
          comments
          local_life_things_to_do {
            id
          }
        }
      }
    }
  }
`);

    const list = data.allStrapiLocalLifeComments.edges

    const [modalCommentformOpen,setCommentformOpen] = React.useState(false);
    const openCommentformModal = () => {
        setCommentformOpen(true);
    }
    const closeCommentformModal = () => {
        setCommentformOpen(false);
    }

  const location = useLocation();
    
  const shareUrl = location.href

  const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

    let cmtsCnt = 0;
    list.map((node) => {
      if(node?.node?.local_life_things_to_do?.id && node?.node?.local_life_things_to_do?.id == props.locallovestrapiid){
        cmtsCnt = 1;
      }
    });
    if(cmtsCnt == 0)
      return null;

      return (
        <div className="mg-favourites mg-fav-others loving-life-comments">
        <div className="container">
          <div className="local-life-new-page-listing local-life-area-landing-page    ">
                  <Row>
                      <Col md={12}>
                          <h2 className="sec-title">Customer Comments</h2>
                      </Col>
                  </Row> 
                  <div className="mg-favourites mg-fav-landing">
                  <div className="tab-content">
                  <Row className="mg-things-do-list row-eq-height">
                      {list.map(({node}, i) => {
                          return <>
                              {node.local_life_things_to_do.id == props.locallovestrapiid &&
                                  <Col xl={6}>
                                        <div className={`favourite-item d-flex`}>
                                            <div className="info">
                                                 <ShowMoreText
                                                  /* Default options */
                                                  lines={3}
                                                  more='More'
                                                  less='Less'
                                                  className='google-review-content-css desc infotext'
                                                  anchorClass='btn-more review-more'
                                                  expanded={false}
                                                  keepNewLines={false}
                                                  >

                                                    {node.comments}
                                                  </ShowMoreText>
                                               <h6 className="info-title">{node.name}</h6>
                                            </div>
                                        </div>
                                  </Col>
                              }
                          </>;
                      })}
                  </Row>
                  </div>
                  </div>
          </div>
        </div>
        <ModalTiny backdrop="static" show={modalCommentformOpen} onHide={closeCommentformModal} className="book-a-viewing-modal share-to-friend-modal">
        <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">ADD COMMENTS</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section"><CommentForm local_life_id={props.locallovestrapiid} local_life_name={props.locallovename} /></ModalTiny.Body>
      </ModalTiny>
        </div>
      );
    }
  export default LocalLifeComments