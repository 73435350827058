import React from "react"
import { graphql } from 'gatsby'
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelpStyleThree"
import EastSchedule from '../components/Static/EastScheduleTwo/EastScheduleTwo'
import MGFav from '../components/Static/MgFavourites/MgFavouritesLocalLifeOthers'
import MGComments from '../components/LocalLoveComments'
import SEO from "../components/seo"

const LocalLifeDetail = (props) => {
    const data = props.data.strapiRestaurants
    const data_gallery = props.data.strapidata
    const res = props.data.allStrapiRestaurants.edges
	return (
		<div className="local-life-details-page">
	        <HeaderTwo />
			
			<SEO location={props.location} title={data.Meta_Title ? data.Meta_Title : data.Name} description={data.Meta_Description ? data.Meta_Description : data.Name} />

	        <div className="content">
	        <EastSchedule data={data} category={data.Category ? data.Category.Name : ''} Title={data.Name} gallery={data_gallery} />

          <MGComments locallovename={data.Name} locallovestrapiid={data.strapiId} />

	        <MGFav data={res} category={data.Category ? data.Category.Name : ''} Title={data.area_guide ? data.area_guide.Title : ''} url={data.area_guide ? data.area_guide.URL : ''}/>

	        <CanHelp />
	        </div>
	        <Footer Select_Popular_Search="Static_Contact" popularSearch="static"/>
		</div>
	)
}

export default LocalLifeDetail

export const pageQuery = graphql`query LocalLifeDetailQuery($slug: String!, $url: String!, $category: String!, $strapiId: ID!) {
  strapiRestaurants(URL: {eq: $slug}) {
    About_Restaurant
    Address
    EatIn
    Takeaways
    Featured
    Email
    Meta_Description
    Meta_Title
    Name
    Offer_MSG
    Phone
    URL
    strapiId
    Category {
      Name
    }
    area_guide {
      URL
      Title
    }
    Gallery {
      Image {
        formats {
          large {
            childImageSharp {
              gatsbyImageData(
                formats: AUTO
                width: 450
                quality: 100
                layout: FIXED
                transformOptions: {cropFocus: CENTER, fit: COVER}
              )
            }
          }
        }
      }
    }
  }
  strapidata {
    restaurant(id: $strapiId) {
      Gallery {
        Image {
          formats
        }
      }
    }
  }
  allStrapiRestaurants(
    filter: {Publish: {eq: true}, area_guide: {URL: {eq: $url}}, Category: {URL: {eq: $category}}, URL: {ne: $slug}}
  ) {
    edges {
      node {
        URL
        Name
        Tile_Image_Label
        Tile_Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 120, layout: CONSTRAINED)
          }
        }
        Short_Description
        Address
        Category {
          URL
        }
        Featured
      }
    }
  }
}
`
