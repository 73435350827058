import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import {Col, Row, Container,Navbar,Nav, Tabs, Tab } from 'react-bootstrap';
import VideoCard from '../VideoCard/VideoCard';
import  "./MgFavourites.scss";
import favImg01 from "../../../images/fav-img01.jpg"
import favImg02 from "../../../images/fav-img02.jpg"
import favImg03 from "../../../images/fav-img03.jpg"
import favImg04 from "../../../images/fav-img04.jpg"
//import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import $ from "jquery"

const MgFavouritesLocalLifeOthers=(props)=>{
  
  const count = props.data.length
  var type = "odd"
  if(count % 2 == 0) {
      type = "even";
  }

  return <>
        {props.data.length != 0 &&
  <section className="mg-favourites mg-fav-others">
    <Container>
        <div className="block">
        {/* <ScrollAnimation animateIn='fadeInUp' animateOnce> */}
        <Row>
          <Col md={8}>
              <h2 className="sec-title">Other {props.category} in {props.Title}</h2>
          </Col>
          <Col md={4}>
            <div className="view-more">
              <Link to={`../${props.url}`} className="btn btn-success">View More</Link>
            </div>
          </Col>
        </Row> 

        <div className="tab-content">
        <Row className="mg-things-do-list">
        {props.data.map(({node}, i) => {
          return (
              <Col xl={6}>
                  <Link to={`/about/loving-life/${node.Category.URL}/${node.URL}`} className="link">
                      <div className={`favourite-item d-flex ${count == i+1 ? 'no-border' : ''} ${type == "even" ? 'no-border' : ''}`}>
                          <figure>
                              {node.Tile_Image ? <img loading="lazy" src={node.Tile_Image.childImageSharp && node.Tile_Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${node.Name} - Martyn Gerrard`} /> : '' }
                              {node.Tile_Image_Label ? <span className="tag-abs text-uppercase">{node.Tile_Image_Label}</span> : '' }
                          </figure>
                          <div className="info">
                              <h4 className="info-title">{node.Name}</h4>
                              <span className="add-txt">{node.Address?.substring(0, 50)} {node.Address?.length > 50 ? '...' : ''}</span>
                              <ReactMarkdown source={node.Short_Description} allowDangerousHtml className=""/>
                          </div>
                      </div>
                  </Link>
              </Col>
          );
        })}
        </Row>
        </div>
        {/* </ScrollAnimation> */}
        </div>
    </Container>
  </section>
        }
        </>;
  }

export default MgFavouritesLocalLifeOthers
