import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import { getUser } from "@myaccountServices/store/utils"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import _ from "lodash";

import axios from "axios"
import * as qs from "query-string"

import $ from "jquery"

function SendFriendForm(props) {

  const userData = getUser();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "Comments",
        error_text: "Highlighted fields are required | invalid",
        success_text: "Thank you for adding your comments.",
        email_temp_user: "comments_user",
        email_temp_admin: "comments_admin",
        email_server_func: "comments",
        event_tracking: "comments",
        page_url: ""
      },
      {
        grpmd: "12",
        label: "Name",
        placeholder: "Your Name *",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18",
        defaultValue: userData?.name || ``
      },
      {
        grpmd: "12",
        label: "Email",
        placeholder: "Your Email Address *",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "content_b-18",
        defaultValue: userData?.email || ``

      },
      {
        grpmd: "12",
        label: "Telephone",
        placeholder: "Your Telephone Number *",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "content_b-18",
        defaultValue: userData?.mobile || ``

      },
      {
        grpmd: "12",
        label: "Message",
        placeholder: "Write your message here *",
        name: "message",
        required: true,
        element: "textarea",
        class: "mb-4",
        rows:"2"
      },
      {
        grpmd: "12",
        name: "Submit Comment",
        type:"submit",
        element: "button",
        value: "submit",
        class: "btn",
        labelClass: "content_b-18"
      },
      {
        text: 'By clicking Submit, you agree to our <a href="/terms-and-conditions/"> Terms &amp; Conditions </a> and <a href="/privacy-policy/"> Privacy Policy </a>',
        element: "html",
        class: "terms modalterms"
      },
      {
        element: "captcha",
        class: "py-2 captcha-class",
        captchaRef: recaptchaRef
      },
      {
        name: "local_life_id",
        type: "hidden",
        element: "input",
        defaultValue: props?.subject?.local_life_id
      },
      {
        name: "local_life_name",
        type: "hidden",
        element: "input",
        defaultValue: props?.subject?.local_life_name
      }
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== '') {

      // lets send mail
      const axiosOptions_email = {
        url: '/.netlify/functions/server/'+fields[0].email_server_func,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_email)
        .then(response => {
          //console.log('mail sent!')
        })
        .catch(err =>
          console.log(err)
        );

      let mystrapidata = {
          "email" : formvalues.email,
          "form_name" : fields[0].formname,
          "formname" : fields[0].formname,
          "message" : formvalues.message,
          "name" : formvalues.name,
          "telephone" : formvalues.telephone,
          "extra" : formvalues,
          "comments" : formvalues.message,
          "local_life_things_to_do" : formvalues.local_life_id
      }

      var strapisettings = {
        "url": process.env.GATSBY_STRAPI_FORM_URL+'/forms',
        "method": "POST",
        "timeout": 0,
        "headers": {
          "Authorization": "Bearer "+process.env.GATSBY_STRAPI_FORM_TOKEN,
          "Content-Type": "application/json"
        },
        "data": JSON.stringify(mystrapidata),
      };

      $.ajax(strapisettings).done(function (response) {
        //console.log(response);
      });

      var strapisettings = {
        "url": process.env.GATSBY_STRAPI_FORM_URL+'/local-life-comments',
        "method": "POST",
        "timeout": 0,
        "headers": {
          "Authorization": "Bearer "+process.env.GATSBY_STRAPI_FORM_TOKEN,
          "Content-Type": "application/json"
        },
        "data": JSON.stringify(mystrapidata),
      };

      $.ajax(strapisettings).done(function (response) {
        //console.log(response);
      });

      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

    }
  }, [token]);

  const handleonVerify = token => {
    //console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['property_id'] = props.subject.property_id ? props.subject.property_id : '';
      json['property_url'] = props.subject.page_url ? props.subject.page_url : '';
      json['property_title'] = props.subject.property_title ? props.subject.property_title : '';
      json['property_list'] = props.subject.properties ? props.subject.properties : '';
      json['property_img'] = props.subject.property_img ? props.subject.property_img : '';

      json['g-recaptcha-response'] = token;
      json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();

    }
  };

  return (
    <div className="form stbform valuation-main share-to-friend">
    <div ref={myRef} />

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}

    {showthankyou && <div className="alert-success">
      <p>{fields[0].success_text}</p>
    </div>}


      <Form name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value={fields[0].formname} />
        <input type="hidden" name="bot-field" />
        <div className="step-block">
        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  defaultValue={field.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
        }
        </Form.Row>
            </div>
      </Form>
    </div>
  );
}


const SendFriendFormPage = (props) => (
  <SendFriendForm subject={props} />
)

export default SendFriendFormPage
